.navigation {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navigation .submenu {
  display: none;
}

.navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.navigation__link {
  text-transform: uppercase;
  position: relative;
  z-index: 3;
  display: inline-block;
  padding: 10px 2px 40px;
  color: #262626;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: -0.5px;
}

.navigation__link:after {
  content: "\e943";
  position: absolute;
  left: 50%;
  bottom: 0;
  color: #62CBC9;
  font-family: icomoon;
  font-size: 30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.navigation__link.active {
  color: #fff;
  background-color: #343434;
}

.navigation__link.active + .submenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navigation__link.active:after,
.navigation__link:hover:after {
  opacity: 1;
  bottom: 5px;
}

@media (min-width: 1200px) {
  .navigation-container {
    max-width: 1430px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .navigation__link {
    padding: 10px 2px 40px;
  }
}

