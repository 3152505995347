.learn-banner {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#bfbfbf),
      to(#d9d9d9)
    ),
    -webkit-gradient(linear, left bottom, left top, from(#303030), to(#1e1e1c));
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -webkit-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -o-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%),
    linear-gradient(to top, #303030 0, #1e1e1c 100%);
}
.learn-banner__info {
  margin: 0 auto;
  max-width: 555px;
  padding: 0 15px;
  width: 100%;
}
.learn-banner__text,
.learn-banner__title {
  color: #262626;
  font-weight: 300;
  margin-bottom: 20px;
}
.learn-banner__title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 55px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
}
.learn-banner__title img {
  display: block;
  margin: 10px;
  max-width: 210px;
}
.learn-banner__text {
  font-size: 18px;
  letter-spacing: -1px;
  line-height: 24px;
  text-align: justify;
}
.learn-banner__text b {
  font-weight: 700;
}
.learn-banner__img {
  display: none;
}
@media (min-width: 992px) {
  .learn-banner__content {
    margin: 0 auto;
    max-width: 1150px;
    padding: 25px 0;
    position: relative;
    width: 100%;
  }
  .learn-banner__info {
    margin-left: 0;
    margin-right: auto;
    max-width: 530px;
  }
  .learn-banner__title {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .learn-banner__title img {
    margin: 0;
  }
  .learn-banner__text {
    margin-bottom: 50px;
  }
  .learn-banner__img {
    bottom: -100px;
    display: block;
    position: absolute;
    right: 0;
    z-index: 100;
  }
  .learn-banner__img img {
    display: block;
  }
}
