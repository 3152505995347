.top-slider .slick-slide > div,
.top-slider__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  width: 100%;
}
.top-slider__btn-wrapper,
.top-slider__content-wrapper h1,
.top-slider__img-wrapper {
  margin-bottom: 20px;
}
.top-slider__section {
  background: -webkit-radial-gradient(
    center,
    ellipse,
    #62aed3 0,
    #2473fa 62%,
    #2473fa 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse,
    #62aed3 0,
    #2473fa 62%,
    #2473fa 100%
  );
  background: radial-gradient(
    ellipse at center,
    #62aed3 0,
    #2473fa 62%,
    #2473fa 100%
  );
}
.top-slider .slick-slide > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.top-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.top-slider .slick-dots,
.top-slider__item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
}
.top-slider .slick-next,
.top-slider .slick-prev {
  width: 30px;
  height: 30px;
}
.top-slider .slick-next:before,
.top-slider .slick-prev:before {
  font-family: icomoon;
  font-size: 30px;
}
.top-slider .slick-prev {
  left: 0;
  z-index: 100;
}
.top-slider .slick-prev:before {
  content: "\e942";
}
.top-slider .slick-next {
  right: 0;
  z-index: 100;
}
.top-slider .slick-next:before {
  content: "\e941";
}
.top-slider .slick-dots {
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: -60px;
  height: 30px;
  z-index: 1000;
  background-image: -webkit-linear-gradient(-266deg,#245EC9,#245EC9);
  background-image: -o-linear-gradient(-266deg,#245EC9,#245EC9);
  background-image: linear-gradient(-266deg,#245EC9,#245EC9);
}
.top-slider .slick-dots li {
  width: auto;
  height: auto;
  margin-left: 10px;
  line-height: 0;
}
.top-slider .slick-dots li:first-child {
  margin-left: 0;
}
.top-slider .slick-dots button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: transparent;
}
.top-slider .slick-dots button:before {
  content: none;
}
.top-slider .slick-dots .slick-active button {
  background-color: #fff;
}
.top-slider__wrapper {
  padding-bottom: 60px;
}
.top-slider__item {
  padding: 0 15px;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.top-slider__btn-wrapper,
.top-slider__img-wrapper {
  text-align: center;
}
.top-slider__content-wrapper {
  color: #fff;
}
.top-slider__content-wrapper h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
}
.top-slider__content-wrapper h1 span {
  display: block;
  font-size: 20px;
  font-weight: 300;
}
.top-slider__content-wrapper h2 {
  font-size: 36px;
  line-height: 36px;
  color: #fff;
  font-weight: 300;
}
.top-slider__content-wrapper h3 {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-weight: 300;
}
.top-slider__content-wrapper ul {
  list-style: none;
}
.top-slider__content-wrapper ul li {
  position: relative;
  padding-left: 20px;
  color: #262626;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}
.top-slider__content-wrapper ul li:before {
  content: "\e941";
  position: absolute;
  top: 0;
  left: 0;
  color: #ff4677;
  font-family: icomoon;
}
@media (min-width: 768px) {
  .top-slider__content-wrapper ul li {
    font-size: 18px;
    line-height: 24px;
  }
}
.top-slider__img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.top-slider__img--shadow {
  -webkit-box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.25);
}
@media (min-width: 600px) {
  .top-slider__item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .top-slider .slick-dots {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0 20px;
  }
  .top-slider__content,
  .top-slider__img-wrapper {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .top-slider__content--left {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .top-slider__img-wrapper {
    margin-bottom: 0;
  }
  .top-slider__wrapper {
    padding-bottom: 45px;
  }
  .top-slider__wrapper.section--top {
    padding-top: 260px;
  }
  .top-slider .slick-dots {
    bottom: -45px;
    height: 50px;
  }
  .top-slider .slick-next,
  .top-slider .slick-prev {
    width: 45px;
    height: 45px;
  }
  .top-slider .slick-next:before,
  .top-slider .slick-prev:before {
    font-size: 45px;
  }
  .top-slider__content-wrapper h1 {
    font-size: 100px;
    line-height: 100px;
  }
  .top-slider__content-wrapper h1 span {
    font-size: 36px;
    line-height: 36px;
  }
  .top-slider__content-wrapper h1 b {
    font-weight: 700;
  }
}
@media (min-width: 1700px) {
  .top-slider .slick-prev {
    left: -100px;
  }
  .top-slider .slick-next {
    right: -100px;
  }
}
