.btn,
.btn:active,
.btn:focus,
.btn:hover {
  outline: 0;
}
.btn,
.btn span,
.btn--switch {
  position: relative;
}
.btn {
  display: inline-block;
  border: none;
  padding: 0;
  color: #fff;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
}
.btn--block {
  max-width: 100%;
  width: 100%;
}
.btn:after,
.btn:before {
  content: "";
  display: block;
  width: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.btn:before {
  left: 0;
  border: 3px solid #F0677E;
  border-right: 0;
  border-radius: 10px 0 0 10px;
  color: #F0677E;
}
.btn:after {
  right: 0;
  border: 3px solid #F0677E;
  border-left: 0;
  border-radius: 0 10px 10px 0;
  color: #F0677E;
}
.btn span {
  display: block;
  padding: 2px 5px;
  margin: 0 10px;
  line-height: 28px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.btn span:after,
.btn span:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  right: 0;
  left: 0;
  background-color: #F0677E;
}
.btn span:before {
  top: 0;
  bottom: auto;
}
.btn span:after {
  top: auto;
  bottom: 0;
}
.btn--disabled,
.btn:disabled {
  opacity: 0.5;
}

.btn:hover:after,
.btn:hover:before,
.btn:hover span,
.btn:hover span {
  background-color: #F0677E;
}
.btn:active:after,
.btn:active:before,
.btn:active span,
.btn:active span {
  background-color: #A71D3F;
}

.btn--black:hover:after,
.btn--black:hover:before,
.btn--black:hover span,
.btn--black:hover span,
.btn--white:hover:after,
.btn--white:hover:before,
.btn--white:hover span,
.btn--white:hover span {
  background-color: transparent;
  border-color: #F0677E;
}

.btn--black:hover span:after,
.btn--black:hover span:before,
.btn--white:hover span:after,
.btn--white:hover span:before {
  background: #F0677E;
}

.btn--black:active:after,
.btn--black:active:before,
.btn--black:active span,
.btn--black:active span,
.btn--white:active:after,
.btn--white:active:before,
.btn--white:active span,
.btn--white:active span {
  background-color: transparent;
  border-color: #A71D3F;
}

.btn--black:active span:after,
.btn--black:active span:before,
.btn--white:active span:after,
.btn--white:active span:before {
  background: #A71D3F;
}


.btn--switch {
  overflow: hidden;
}
.btn--switch input {
  position: absolute;
  top: 0;
  left: -40px;
}
.btn--switch input ~ i {
  font-style: normal;
}
.btn--switch input ~ i:before {
  content: attr(data-unchecked);
}
.btn--switch input:checked ~ i:before {
  content: attr(data-checked);
}
.btn__text {
  color: inherit;
}
.btn__text--black {
  color: #262626;
}
.btn__text--white {
  color: #fff;
}
.btn--md {
  max-width: 160px;
  width: 100%;
}
.btn--high span {
  padding: 10px 5px;
  font-size: 16px;
}
.btn--high.btn--md {
  max-width: 250px;
  width: 100%;
}
.btn--high.btn--lg {
  max-width: 300px;
  width: 100%;
}
.btn--black {
  color: #262626;
}
.btn--black:after,
.btn--black:before {
  border-color: #262626;
  color: #262626;
}
.btn--black span:after,
.btn--black span:before {
  background: #262626;
}
.btn--white {
  color: #fff;
}
.btn--white:after,
.btn--white:before {
  border-color: #fff;
  color: #fff;
}
.btn--white span:after,
.btn--white span:before {
  background: #fff;
}
.btn__icon {
  position: absolute;
  top: 50%;
  font-size: 22px;
  line-height: 22px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--icon-left span,
.btn--icon-right span {
  padding-left: 25px;
  padding-right: 25px;
}
.btn--icon-left .btn__icon {
  left: 0;
}
.btn--icon-right .btn__icon {
  right: 0;
}
