.bragging {
  background-color: #f1f1f1;
  padding-top:286px
}
.bragging__title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.bragging__title span {
  font-weight: 300;
}
.bragging__wrapper {
  background-color: #f1f1f1;
}
.bragging__content {
  padding: 0 15px;
}
.bragging__img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.bragging__list {
  list-style: none;
  margin-bottom: 30px;
}
.bragging__item-wrapper {
  margin-bottom: 25px;
}
.bragging__item-wrapper:last-child {
  margin-bottom: 0;
}
.bragging__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bragging__list-text {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: -1px;
}
.bragging__icon {
  color: #262626;
  font-size: 50px;
  margin-right: 10px;
}
.bragging__subtitle {
  margin-bottom: 10px;
  color: #262626;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: -0.5px;
}
@media (min-width: 768px) {
  .bragging__title {
    font-size: 35px;
  }
  .bragging__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .bragging__img-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 10px;
  }
  .bragging__list {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-top: 15px;
    margin-bottom: 0;
  }
  .bragging__item-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 30px;
  }
  .bragging__item-wrapper:before {
    content: "\e941";
    position: absolute;
    top: 50%;
    left: 0;
    color: #62CBC9;
    font-size: 30px;
    font-family: icomoon;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .bragging__item {
    max-width: 480px;
    width: 100%;
  }
  .bragging__icon {
    font-size: 55px;
  }
  .bragging__subtitle {
    margin-bottom: 5px;
  }
}
@media (min-width: 992px) {
  .bragging__title {
    margin-bottom: 50px;
    font-size: 55px;
  }
  .bragging__info {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 10px;
  }
  .bragging__item-wrapper {
    margin-bottom: 40px;
  }
  .bragging__icon,
  .bragging__img-wrapper {
    margin-right: 30px;
  }
}
