.submission {
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#bfbfbf),
      to(#d9d9d9)
    ),
    -webkit-gradient(linear, left bottom, left top, from(#303030), to(#1e1e1c));
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -webkit-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -o-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%),
    linear-gradient(to top, #303030 0, #1e1e1c 100%);
}
.submission .arrow-list {
  margin-bottom: 35px;
}
.submission .arrow-list__item {
  font-weight: 300;
  text-align: justify;
}
.submission .arrow-list__item b {
  font-weight: 700;
}
.submission__wrapper {
  background-color: #f1f1f1;
}
.submission__content {
  padding: 0 15px;
}
.submission__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #262626;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -2px;
}
.submission__title--img {
  display: block;
  margin: 5px 0 5px 10px;
}
.submission__steps {
  margin-bottom: 30px;
}
.submission__step {
  max-width: 300px;
  width: 100%;
  margin: 0 auto 25px;
  text-align: center;
}
.submission__step:last-child {
  margin-bottom: 0;
}
.submission-step__title {
  margin-bottom: 15px;
  color: #262626;
  font-size: 18px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
}
.submission-step__title span {
  color: #008375;
  font-weight: 700;
}
.submission__text {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: -0.5px;
}
.submission__text b {
  font-weight: 700;
}
.submission__process-wrapper {
  margin-bottom: 35px;
  text-align: center;
}
.submission__process-wrapper .btn {
  max-width: 220px;
  width: 100%;
}
.submission__info {
  overflow: hidden;
}
.submission__info .submission__text {
  margin-bottom: 20px;
  text-align: justify;
}
.submission__info .submission__text:first-of-type:first-letter {
  font-size: 60px;
  font-weight: 700;
  line-height: 55px;
  margin-right: 5px;
  margin-bottom: 5px;
  float: left;
}
.submission__img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .submission__steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .submission__step {
    padding: 0 10px;
  }
  .submission-step__title {
    font-size: 16px;
  }
  .submission__text {
    font-size: 14px;
  }
  .submission__img {
    max-width: 50%;
  }
  .submission__img--right {
    float: right;
    margin-left: 2rem;
  }
  .submission__img--left {
    float: left;
    margin-right: 2rem;
  }
}
@media (min-width: 992px) {
  .submission .arrow-list__item,
  .submission__text {
    line-height: 30px;
    font-size: 22px;
  }
  .submission__process:before,
  .submission__step:after,
  .submission__step:before {
    content: "";
    position: absolute;
    display: block;
  }
  .submission .section__line-through {
    font-size: 36px;
    margin-bottom: 50px;
  }
  .submission .arrow-list__item {
    padding-left: 25px;
    text-align: left;
  }
  .submission__content {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
  .submission__steps {
    margin-bottom: 0;
  }
  .submission__step {
    position: relative;
    max-width: 360px;
    padding: 0 0 35px 45px;
    margin-left: 35px;
    margin-bottom: 0;
    text-align: left;
  }
  .submission__step:first-child {
    margin-left: 0;
  }
  .submission__step:before {
    top: 10px;
    left: 0;
    bottom: 0;
    width: 35px;
    border: 3px dotted #262626;
    border-right: none;
    border-bottom: none;
    border-radius: 20px 0 0;
  }
  .submission__step:after {
    left: -2px;
    bottom: -19px;
    z-index: 10;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #f1f1f1;
    -webkit-box-shadow: 0 0 0 5px #262626, 0 0 0 12px #f1f1f1;
    box-shadow: 0 0 0 5px #262626, 0 0 0 12px #f1f1f1;
  }
  .submission-step__title {
    font-size: 22px;
  }
  .submission__process-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .submission__process {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 18px;
    margin-right: 30px;
    background-image: -webkit-linear-gradient(356deg, #2473FA 0, #62AED3 100%);
    background-image: -o-linear-gradient(356deg, #2473FA 0, #62AED3 100%);
    background-image: linear-gradient(-266deg, #2473FA 0, #62AED3 100%);
  }
  .submission__process:before {
    top: 0;
    right: -18px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-left-color: #62AED3;
  }
  .submission__info .submission__text:first-of-type:first-letter {
    font-size: 110px;
    line-height: 95px;
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  .submission__img {
    max-width: 100%;
  }
  .submission__img--right {
    float: right;
    margin-left: 80px;
  }
}
