.submenu {
  position: absolute;
  top: 100%;
  left: 25px;
  right: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 25px 30px 5px;
  background-color: rgba(255, 255, 255, 0.75);
  border-width: 10px 10px 10px 0;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(bottom, #2473FA 0, #62AED3) 1
    100%;
  -o-border-image: -o-linear-gradient(bottom, #2473FA 0, #62AED3) 1 100%;
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  border-image: linear-gradient(to top, #2473FA 0, #62AED3) 1 100%;
  z-index: 2;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}
.submenu__item {
  margin-left: 45px;
}
.submenu__item:first-child {
  margin-left: 0;
}
.submenu__link {
  color: #262626;
  font-weight: 700;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.submenu__link.active,
.submenu__link:hover {
  color: #F0677E;
}
.submenu__link-submit {
  color: #262626;
  font-weight: 700;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
