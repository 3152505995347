.join__list {
  max-width: 500px;
  width: 100%;
}
.join {
  background-image: -webkit-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
  background-image: -o-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
  background-image: linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
}
.join .form-checkbox {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.join__list {
  padding: 20px 0;
}
.join__list-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.join__list-text {
  color: #fff;
  font-family: Area, sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-left: 10px;
  word-wrap: break-word;
}
.join__list-text b {
  font-size: 16px;
  font-weight: 600;
}
.join__footer {
  text-align: center;
}
.join__sing-in {
  color: #fff;
  font-size: 12px;
  margin-top: 20px;
}
.join__sing-in button {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-weight: 700;
  padding: 0;
}
.join__sing-in button:focus,
.join__sing-in button:hover {
  outline: 0;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .join__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 30px;
    max-width: 1150px;
    width: 100%;
  }
  .join__item {
    width: 50%;
    max-width: 500px;
  }
  .join__fields {
    display: inline-block;
    width: 100%;
  }
  .join__list {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    padding: 10px 0;
  }
  .join__list-item {
    margin-bottom: 30px;
  }
  .join__list-text {
    letter-spacing: -1px;
  }
}
@media (min-width: 992px) {

  .join__list-item {
    margin-bottom: 25px;
  }
  .join__list-text {
    font-size: 16px;
  }
}
