.size {
  background-color: #000000;
}
.size .square {
  position: relative;
  margin: 0 auto 10px;
}
.size__title {
  position: relative;
  padding-left: 30px;
  margin-bottom: 25px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.size__title:before {
  content: "\e941";
  position: absolute;
  top: 50%;
  left: 0;
  color: #62CBC9;
  font-size: 24px;
  font-family: icomoon;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.size__subtitle {
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  text-align: justify;
  line-height: 24px;
  letter-spacing: -0.8px;
}
.size__item,
.size__list-item {
  margin-bottom: 30px;
}
.size__label b,
.size__subtitle b,
.size__value {
  font-weight: 700;
}
.size__content {
  padding: 0 15px;
}
.size__list {
  list-style: none;
}
.size__list-item:last-child {
  margin-bottom: 0;
}
.size__value {
  color: #fff;
  font-size: 30px;
  letter-spacing: -1.2px;
}
.size__label,
.size__list-text {
  font-weight: 300;
  line-height: 24px;
  color: #fff;
}
.size__value:after,
.size__value:before {
  content: "";
  position: absolute;
  right: 25px;
  z-index: 10;
  display: block;
  width: 2px;
  height: 30px;
  background-color: #fff;
}
.size__value:before {
  top: 5px;
}
.size__value:after {
  bottom: 5px;
}
.size__valueh {
  color: #fff;
  font-size: 30px;
  letter-spacing: -1.2px;
}
.size__valueh:after,
.size__valueh:before {
  content: "";
  position: absolute;
  top: 50px;
  z-index: 10;
  display: block;
  width: 10px;
  height: 2px;
  background-color: #fff;
}
.size__valueh:before {
  left: -5px;
}
.size__valueh:after {
  right: -5px;
}
.size__label {
  margin-bottom: 10px;
  font-size: 18px;
  letter-spacing: -1px;
}
.size__list-text {
  font-size: 16px;
  letter-spacing: -0.8px;
  text-align: justify;
}
.size__cases {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.size__case {
  max-width: 275px;
  width: 100%;
  padding: 20px;
  margin: 0 auto 20px;
  border: 2px dotted #fff;
  border-radius: 10px;
}
.size__case-icon {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  color: #fff;
  font-size: 220px;
  text-align: center;
}
.size__case-title {
  padding: 10px 5px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
.size__tooltip {
  font-size: 16px;
  padding: 0 2px;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .size .square {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin: 0 30px 0 0;
  }
  .size__list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .size__label {
    margin-bottom: 5px;
  }
  .size__case-title {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .size .section__line-through {
    font-size: 36px;
  }
  .size__title {
    margin-bottom: 50px;
    font-size: 30px;
  }
  .size__subtitle {
    margin-bottom: 45px;
    font-size: 22px;
    line-height: 30px;
  }
  .size__content {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
  .size__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .size__item {
    max-width: 50%;
  }
  .size__list {
    padding-right: 45px;
  }
  .size__cases {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .size__case {
    margin-left: 20px;
    margin-bottom: 0;
  }
  .size__case:first-child {
    margin-left: 0;
  }
}
