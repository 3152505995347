.submit-game {
  background-image: -webkit-linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
  background-image: -o-linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
  background-image: linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
}
.submit-game__submenu .submenu__link,
.submit-game__submenu .submenu__link-submit {
  opacity: 0.3;
}
.submit-game__submenu .submenu__link:hover,
.submit-game__submenu .submenu__link-submit:hover {
  color: #262626;
}
.submit-game__submenu .submenu__link.active,
.submit-game__submenu .submenu__link-submit.active {
  color: #262626;
  opacity: 1;
}
.submit-game__submenu .submenu__link.active span {
  color: #ff4677;
}
.submit-game__submenu .submenu__link-submit.active span {
  color: #62cbc9;
}
.submit-game__content {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#bfbfbf),
    to(#d9d9d9)
  );
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%);
  width: 100%;
}
.submit-game__section {
  background-color: #e5e5e5;
}
.submit-game__item {
  margin-bottom: 30px;
}
.submit-game__footer .btn--high {
  margin-bottom: 15px;
}
.submit-game__footer .btn--high.btn--lg {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 300px;
  flex: 1 1 300px;
}
@media (min-width: 992px) {
  .submit-game__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0;
  }
  .submit-game__footer {
    margin: 0 auto;
    max-width: 960px;
    padding: 50px 15px 0;
    width: 100%;
  }
  .submit-game__footer .btn--high {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .submit-game__section {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }
}
