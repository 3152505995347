.case {
  background-color: #000000;
}
.case .home__title {
  margin-bottom: 35px;
}
.case__content {
  position: relative;
}
.case__info {
  margin-bottom: 40px;
}
.case__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  color: #fff;
  text-align: left;
}
.case__list li:before {
  position: absolute;
  top: 5px;
  font-family: icomoon;
  color: #62CBC9;
  font-size: 14px;
  line-height: 14px;
  text-shadow: 3px 0 0 #fff, -3px 0 0 #fff, 0 3px 0 #fff, 0 -3px 0 #fff,
    2px 2px #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff;
}
.case__list li.diamond:before {
  content: "\e97f";
}
.case__list li.circle:before {
  content: "\e97e";
}
.case__list li.x:before {
  content: "\e97c";
}
.case__list li.triangle:before {
  content: "\e97d";
}
.case__img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.case__btn-wrapper {
  text-align: center;
}
@media (min-width: 500px) {
  .case__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .case__info {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .case__img-wrapper {
    display: block;
  }
  .case__btn-wrapper {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .case__list,
  .case__list li {
    margin-bottom: 30px;
  }
  .case .home__title,
  .case__btn-wrapper,
  .case__list li {
    text-align: right;
  }
  .case__info {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }
  .case__list li {
    position: relative;
    display: block;
    padding-left: 0;
    padding-right: 30px;
  }
  .case__list li:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: icomoon;
    color: #62CBC9;
    font-size: 14px;
    line-height: 14px;
    text-shadow: 3px 0 0 #fff, -3px 0 0 #fff, 0 3px 0 #fff, 0 -3px 0 #fff,
      2px 2px #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff;
  }
  .case__list li.circle:before,
  .case__list li.diamond:before,
  .case__list li.triangle:before,
  .case__list li.x:before {
    content: "\e941";
  }
  .case__list li.diamond:after {
    content: "\e97f";
  }
  .case__list li.circle:after {
    content: "\e97e";
  }
  .case__list li.x:after {
    content: "\e97c";
  }
  .case__list li.triangle:after {
    content: "\e97d";
  }
  .case__list li:before {
    position: static;
    margin-right: 5px;
    font-size: 18px;
    text-align: right;
    text-shadow: none;
  }
  .case__list li:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .case__info {
    max-width: 800px;
    width: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .case__list li {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .case__content {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 450px;
  }
  .case__list {
    margin-bottom: 35px;
  }
  .case__list li {
    margin-bottom: 25px;
  }
  .case__img-wrapper {
    position: absolute;
    top: -100px;
    right: 0;
    z-index: 30;
  }
}
@media (min-width: 1460px) {
  .case .home__title {
    letter-spacing: -2px;
  }
  .case__content {
    padding-right: 550px;
  }
  .case__list {
    margin-right: 50px;
    margin-bottom: 35px;
  }
  .case__list li {
    margin-bottom: 45px;
    letter-spacing: -1.2px;
  }
  .case__img-wrapper {
    top: -85px;
  }
  .case__btn-wrapper {
    padding-right: 85px;
  }
}
