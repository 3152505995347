.submission-banner__container {
  background-color: #ffffff;
}
.submission-banner__content {
  padding: 0 15px;
}
.submission-banner__header,
.submission-banner__header .banner__text {
  text-align: left;
}
.submission-banner__companies {
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 5px solid #262626;
}
@media (min-width: 992px) {
  .submission-banner__header {
    max-width: 1100px;
    width: 100%;
  }
  .submission-banner__header .banner__text {
    font-size: 18px;
    line-height: 26px;
  }
  .submission-banner__consoles {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
}
