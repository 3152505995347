@media (min-width: 992px) {
  .logo {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: block;
    padding: 20px 40px 30px;
    position: relative;
    overflow: hidden;
  }
  .logo__img {
    display: block;
    max-width: 100%;
  }
  .logo__img--flat {
    display: block;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top: 12px;
    max-width: 100%;
  }
  .header--sticky .logo:after {
    -webkit-transform: translate(0, 103px);
    -ms-transform: translate(0, 103px);
    transform: translate(0, 103px);
  }
}
@media (min-width: 1200px) {
  .logo {
    padding: 20px 80px 30px;
  }
  .logo:after {
    right: 80px;
  }
}
