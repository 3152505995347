.process {
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#bfbfbf),
      to(#d9d9d9)
    ),
    -webkit-gradient(linear, left bottom, left top, from(#303030), to(#1e1e1c));
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -webkit-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -o-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%),
    linear-gradient(to top, #303030 0, #1e1e1c 100%);
}
.process__nav-icons,
.process__nav-labels,
.process__radio {
  display: none;
}
.process__radio:checked ~ .process__label {
  color: #fff;
  border-bottom: none;
  background-image: -webkit-linear-gradient(356deg, #61aed4 0, #2573fa 100%);
  background-image: -o-linear-gradient(356deg, #61aed4 0, #2573fa 100%);
  background-image: linear-gradient(-266deg, #61aed4 0, #2573fa 100%);
}
.process__radio:checked ~ .process__label:before {
  color: #ff4677;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.process__radio:checked ~ .process__content {
  max-height: 2000px;
  padding: 20px;
  margin-top: 0;
}
.process__wrapper {
  background-color: #f2f2f2;
}
.process__label {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 15px 5px;
  color: #f1f1f1;
  font-size: 18px;
  font-weight: 700;
  background: #bebebe;
  border-bottom: 1px solid #dadada;
}
.process__label:before {
  content: "\e941";
  font-family: icomoon;
  margin-right: 20px;
  color: #343434;
  font-size: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.process__content {
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.process__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.process__title span {
  font-weight: 400;
}
.process__text {
  color: #262626;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}
.process__btn-wrapper {
  text-align: center;
}
@media (min-width: 768px) {
  .process .section {
    padding-bottom: 0;
  }
  .process__items,
  .process__nav {
    margin-left: -15px;
    margin-right: -15px;
  }
  .process__nav-icons,
  .process__nav-labels {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
  }
  .process__nav-icons li,
  .process__nav-labels li {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 16.6%;
    flex-basis: 16.6%;
    text-align: center;
  }
  .process__nav-icons label,
  .process__nav-labels label {
    display: inline-block;
    color: #262626;
  }
  .process__nav-labels label {
    padding: 10px 0;
    font-size: 18px;
    font-weight: 700;
    background-color: transparent;
  }
  .process__nav-icons li {
    font-size: 50px;
  }
  .process__nav-icons label {
    padding: 20px 0 40px;
  }
  .process__nav-icons label.active {
    position: relative;
    color: #fff;
  }
  .process__nav-icons label.active:after,
  .process__nav-icons label.active:before {
    content: "";
    left: 50%;
    width: 0;
    height: 0;
    bottom: -12px;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
  }
  .process__nav-icons label.active:after {
    border-color: transparent transparent #fff;
    border-width: 20px;
    margin-left: -20px;
  }
  .process__nav-icons label.active:before {
    border-color: transparent transparent #262626;
    border-width: 36px;
    margin-left: -36px;
  }
  .process__icons-wrapper {
    background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
    background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
    background-image: linear-gradient(-266deg, #62aed3, #2473fa);
  }
  .process__label:before {
    content: none;
  }
  .process__items .process__label {
    display: none;
  }
  .process__radio:checked ~ .process__content {
    position: static;
    max-height: none;
    visibility: visible;
  }
  .process__content {
    position: absolute;
    max-height: none;
    border: 12px solid #262626;
    visibility: hidden;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
@media (min-width: 992px) {
  .process__radio:checked ~ .process__content {
    padding: 40px 40px 30px;
  }
  .process__title {
    font-size: 36px;
  }
  .process__text {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  .process .arrow-list {
    padding-left: 35px;
  }
}
@media (min-width: 1200px) {
  .process__radio:checked ~ .process__content {
    padding: 40px 135px 30px;
  }
  .process__nav-icons,
  .process__nav-labels {
    max-width: 1235px;
  }
}
