.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.vertical-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.img--responsive {
  max-width: 100%;
}
.img--center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hidden {
  display: none !important;
}
.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.horizontal-space-between,
.horizontal-start {
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
}
.horizontal-start {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.horizontal-space-between {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.horizontal-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.text-gradient {
  color: #79ccff;
}
.text-gradient--ytr {
  color: #f8ba64;
}
.disabled {
  pointer-events: none;
  cursor: default;
}
@supports (-webkit-text-fill-color: transparent) {
  .text-gradient {
    background: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
    background: -o-linear-gradient(-266deg, #62aed3, #2473fa);
    background: linear-gradient(-266deg, #62aed3, #2473fa);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  .text-gradient--ytr {
    background-image: -webkit-linear-gradient(248deg, #f8ba64 0, #f6687f 100%);
    background-image: -o-linear-gradient(248deg, #f8ba64 0, #f6687f 100%);
    background-image: linear-gradient(-158deg, #f8ba64 0, #f6687f 100%);
  }
}
