.header {
  display: none;
}
@media (min-width: 992px) {
  .header {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    top: 0;
    display: block;
    background-color: #FBFBFB;
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35);
    z-index: 120;
    -webkit-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
  }
  .header--sticky {
    position: fixed;
    z-index: 9997;
    background-color: #f1f1f1;
  }
  .header--sticky .submenu {
    background-color: #fff;
    -webkit-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
  }
  .header--sticky .submenu.active {
    color: #ff4677;
  }
  .header--sticky .header-top {
    display:none;
  }
  .header--sticky div.navigation-container {
    background-image: none;
  }
  .header__nav {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
}
