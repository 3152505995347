.event__date-item:last-child .event__number:after,
.event__list li:last-child:after {
  content: none;
}
.event {
  margin-bottom: 20px;
}
.event__date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #262626;
  text-align: center;
}
.event__accept-wrapper,
.event__content {
  padding: 10px;
  text-align: center;
  background-color: #fff;
}
.event__date-item .event__number {
  position: relative;
}
.event__date-item .event__number:after {
  content: "-";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.event__number {
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}
.event__month {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.event__name-wrapper {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #262626;
}
.event__name-wrapper:before {
  content: attr(title);
  display: block;
  color: #262626;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.event__name {
  margin-bottom: 5px;
  color: #262626;
  font-size: 16px;
  font-weight: 700;
}
.event__name:before {
  content: "\e941";
  color: #62cbc9;
  font-family: icomoon;
}
.event__accept-wrapper:before,
.event__url-wrapper:before {
  content: attr(title);
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.event__list {
  list-style: none;
}
.event__list li {
  display: inline-block;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}
.event__list li:after {
  content: "|";
  margin: 0 5px;
}
.event__url-wrapper:before {
  color: #262626;
  text-transform: uppercase;
}
.event__url {
  word-wrap: break-word;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.event__url:hover {
  color: #ff4677;
}
.event__accept-wrapper {
  border-top: 1px solid #262626;
  border-bottom: 10px solid #262626;
}
.event__accept-wrapper:before {
  color: #262626;
  text-transform: capitalize;
}
.event__accept {
  color: #262626;
  font-size: 55px;
}
@media (min-width: 768px) {
  .event__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .event__name-wrapper {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }
  .event__url-wrapper {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 992px) {
  .event,
  .event__accept-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
  }
  .event {
    display: flex;
    min-height: 80px;
    margin-bottom: 2px;
  }
  .event--white-label:first-child .event__accept-wrapper:before,
  .event--white-label:first-child .event__name-wrapper:before,
  .event--white-label:first-child .event__url-wrapper:before {
    color: #fff;
  }
  .event__accept-wrapper,
  .event__date-wrapper {
    -ms-flex-preferred-size: 15%;
    flex-basis: 15%;
  }
  .event__date-wrapper {
    margin-right: 5px;
  }
  .event__accept-wrapper {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 5px;
    border: none;
  }
  .event__date {
    padding: 5px;
  }
  .event__content {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
    text-align: left;
    padding: 0;
  }
  .event__accept-wrapper,
  .event__name-wrapper,
  .event__url-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .event__accept-wrapper:before,
  .event__name-wrapper:before,
  .event__url-wrapper:before {
    content: none;
  }
  .event__name-wrapper {
    padding: 0 10px;
  }
  .event__list {
    padding-left: 15px;
  }
  .event__url-wrapper {
    padding: 0 10px 0 20px;
  }
  .event__url-wrapper:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 2px;
    height: 40px;
    background-color: #cbcbcb;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .event:first-child .event__accept-wrapper:before,
  .event:first-child .event__name-wrapper:before,
  .event:first-child .event__url-wrapper:before {
    content: attr(title);
    position: absolute;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    letter-spacing: -0.5px;
  }
}
.event__column-wrapper-white:before {
  color: #ffffff;
}
