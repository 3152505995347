.logo-slider .slick-next:before,
.logo-slider .slick-prev:before,
.logo-slider__title:before {
  font-size: 30px;
  font-family: icomoon;
}
.logo-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo-slider .slick-next,
.logo-slider .slick-prev {
  width: 30px;
  height: 30px;
}
.logo-slider .slick-prev {
  left: 15px;
  z-index: 100;
}
.logo-slider .slick-prev:before {
  content: "\e942";
}
.logo-slider .slick-next {
  right: 15px;
  z-index: 100;
}
.logo-slider .slick-next:before {
  content: "\e941";
}
.logo-slider__wrapper {
  background-color: #000000;
}
.logo-slider__content {
  position: relative;
}
.logo-slider__content:after,
.logo-slider__content:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 10;
  display: block;
  width: 50%;
  height: 100%;
}
.logo-slider__content:after {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.75)),
    to(rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    right,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  right: -40%;
}
.logo-slider__content:before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.75)),
    to(rgba(255, 255, 255, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.75) 0,
    rgba(255, 255, 255, 0) 100%
  );
  left: -40%;
}
.logo-slider__title {
  position: relative;
  z-index: 15;
  padding-bottom: 40px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}
.logo-slider__title:before {
  content: "\e943";
  position: absolute;
  left: 50%;
  bottom: 0;
  color: #62CBC9;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.logo-slider__item {
  padding: 0 100px;
}
.logo-slider__img {
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 400px) {
  .logo-slider__item {
    padding: 0 40px;
  }
}
@media (min-width: 640px) {
  .logo-slider__item {
    padding: 0 60px;
  }
}
@media (min-width: 768px) {
  .logo-slider__item {
    padding: 0 30px;
  }
}
@media (min-width: 992px) {
  .logo-slider .slick-next,
  .logo-slider .slick-prev {
    width: 45px;
    height: 45px;
  }
  .logo-slider .slick-next:before,
  .logo-slider .slick-prev:before {
    font-size: 45px;
  }
  .logo-slider__title {
    padding-bottom: 55px;
    margin-bottom: 25px;
  }
}
@media (min-width: 1700px) {
  .logo-slider .slick-prev {
    left: -100px;
  }
  .logo-slider .slick-next {
    right: -100px;
  }
}
