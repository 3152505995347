.add-ons__checked-item:last-child,
.add-ons__service-info .form-field {
  margin-bottom: 0;
}
.add-ons__row {
  align-items: start;
}
.add-ons__list {
  width: 100%;
  list-style: none;
  margin-bottom: 30px;
}
.add-ons__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}
.add-ons__service-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-ons__service-icon {
  margin-right: 10px;
  font-size: 30px;
}
.add-ons__service-title {
  margin-left: 10px;
  color: #343434;
  font-size: 16px;
  font-weight: 700;
}
.add-ons__service-price {
  color: #262626;
  font-size: 24px;
  font-weight: 700;
}
.add-ons__service-old-price {
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  padding-right: 24px;
}
.add-ons__service-old-price:before {
  position: absolute;
  content: "";
  left: 0;
  top: .4em;
  right: 20px;
  border-top: 6px solid;
  border-color: red;
  -webkit-transform: rotate(-18deg);
  -moz-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  -o-transform: rotate(-18deg);
  transform: rotate(-18deg);
}
.add-ons__box {
  position: relative;
  width: 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding: 12px;
}
.add-ons__box:after,
.add-ons__box:before {
  content: "";
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  position: absolute;
  pointer-events: none;
}
.add-ons__box:after {
  border-color: #fff transparent transparent;
  border-width: 15px;
  margin-left: -15px;
}
.add-ons__box:before {
  border-color: #262626 transparent transparent;
  border-width: 30px;
  margin-left: -30px;
}
.add-ons__box-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #bebebe;
  padding-bottom: 5px;
}
.add-ons__box-header .select-game__label {
  color: #bebebe;
  margin-bottom: 0;
}
.add-ons__box-content {
  padding-top: 20px;
}
.add-ons__box-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
}
.add-ons__declared-price {
  color: #bebebe;
  font-size: 14px;
}
.add-ons__checked-item,
.add-ons__total-label,
.add-ons__total-price {
  color: #262626;
  font-weight: 700;
}
.add-ons__total-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}
.add-ons__total-label:before {
  content: "\e941";
  color: #62CBC9;
  font-family: icomoon;
  font-size: 18px;
}
.add-ons__total-price {
  font-size: 16px;
}
.add-ons__checked-list {
  padding: 5px 0;
}
.add-ons__checked-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .add-ons__services.submit-game__item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .add-ons__list {
    margin-bottom: 30px;
  }
  .add-ons__item {
    margin-bottom: 15px;
  }
  .add-ons__item:last-child {
    margin-bottom: 0;
  }
}
