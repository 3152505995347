.scale-content {
  margin-bottom: 30px;
}
.scale-content .square--big {
  margin-bottom: 25px;
}
.scale-content__label {
  font-size: 72px;
  font-weight: 700;
  letter-spacing: -2px;
}
.scale-content__text {
  margin-bottom: 25px;
  color: #262626;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  text-align: justify;
}
.scale-content__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.scale-content__item {
  max-width: 305px;
  width: 100%;
  margin: 0 15px 30px;
  text-align: center;
}
.scale-content__img-wrapper {
  display: inline-block;
  border: 2px solid #fff;
  margin-bottom: 20px;
}
.scale-content__item-img {
  display: block;
  max-width: 100%;
  width: 100%;
}
.scale-content__item-title {
  color: #262626;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
}
.scale-content__item-title b {
  font-weight: 700;
}
.scale-content__item-title span {
  color: #62CBC9;
}
.scale-content__item-text {
  color: #262626;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -1px;
}
@media (min-width: 768px) {
  .scale-content .square--big {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 25px;
    margin-bottom: 0;
  }
  .scale-content__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .scale-content__text {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .scale-content {
    margin-bottom: 60px;
  }
  .scale-content .section__line-through {
    font-size: 36px;
  }
  .scale-content .square--big {
    margin-right: 45px;
  }
  .scale-content__info {
    margin-bottom: 50px;
  }
  .scale-content__items {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .scale-content__item {
    margin: 0;
  }
  .scale-content__text {
    font-size: 22px;
    line-height: 30px;
  }
}
