.choice-option__content.disabled,
.choice-option__info.disabled {
  opacity: 0.5;
  cursor: default;
}
.choice-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
}
.choice-option__item {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
  color: #fff;
  overflow: hidden;
  position: relative;
}
.choice-option__item-container {
  display: block;
}
.choice-option__radio {
  left: -9999px;
  position: absolute;
}
.choice-option__content {
  display: block;
  padding: 20px 10px;
  background-color: #262626;
}
.choice-option__content.checked {
  padding: 20px 10px;
  background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: linear-gradient(-266deg, #62aed3, #2473fa);
}
.choice-option__content.checked:after {
  display: none;
}
.choice-option__icon {
  display: inline-block;
  font-size: 50px;
  margin-bottom: 10px;
}
.choice-option__text {
  font-weight: 700;
}
.choice-option__info {
  position: relative;
  display: block;
  padding: 20px 5px;
  background-color: #fff;
}
.choice-option__info-text {
  margin-bottom: 20px;
  color: #262626;
  font-size: 20px;
  font-weight: 300;
}
.choice-option__info-text b {
  font-size: 700;
}
.choice-option__info-text span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
}
.choice-option__info-price {
  color: #191919;
  font-size: 24px;
  font-weight: 700;
}
.choice-option__info-old-price {
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  padding-right: 24px;
}
.choice-option__info-old-price:before {
  position: absolute;
  content: "";
  left: 0;
  top: .4em;
  right: 20px;
  border-top: 6px solid;
  border-color: red;
  -webkit-transform: rotate(-18deg);
  -moz-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  -o-transform: rotate(-18deg);
  transform: rotate(-18deg);
}
@media (min-width: 500px) {
  .choice-option {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .choice-option__item {
    background-color: #262626;
  }
  .choice-option--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choice-option--content .choice-option__item-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .choice-option--content .choice-option__content {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 130px;
    width: 100%;
  }
  .choice-option--content .choice-option__info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  .choice-option--content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .choice-option--content .choice-option__item {
    width: 50%;
  }
  .choice-option__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 12px 15px 20px;
  }
  .choice-option__info-text {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
  .choice-option__info.disabled {
    opacity: 0.5;
    cursor: default;
  }
}
@media (min-width: 992px) {
  .choice-option,
  .choice-option__content,
  .choice-option__item,
  .choice-option__item-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .choice-option {
    position: relative;
    max-width: 140px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choice-option__text {
    max-width: 240px;
  }
  .choice-option .form-field__item {
    border-color: #343434;
    padding-right: 5px;
  }
  .choice-option:after {
    content: "\e941";
    position: absolute;
    top: 10px;
    right: -30px;
    color: #62cbc9;
    font-family: icomoon;
    font-size: 30px;
  }
  .choice-option--content {
    max-width: 100%;
  }
  .choice-option--content .choice-option__item {
    width: 100%;
  }
  .choice-option--content .choice-option__item-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .choice-option__info {
    padding-right: 25px;
  }
  .choice-option__info-price-block {
    width: 100%;
    text-align: right;
  }
  .choice-option__info:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 12px;
    background-color: #262626;
  }
  .choice-option__info.checked:after {
    background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
    background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
    background-image: linear-gradient(-266deg, #62aed3, #2473fa);
  }
  .choice-option__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choice-option__item--border {
    border-bottom: 10px solid #262626;
  }
  .choice-option__item-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choice-option__content {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .choice-option__content:after {
    content: "";
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    display: block;
    width: 80%;
    height: 2px;
    background-color: #363636;
  }
  .choice-option__content.checked:after {
    content: none;
  }
}
@media (min-width: 1200px) {
  .choice-option:after {
    right: -50px;
  }
}
