.price-list {
  padding: 5px 0;
  margin-bottom: 10px;
  list-style: none;
}
.price-list__label--border,
.price-list__price--border {
  align-self: flex-end;
  padding-bottom: 5px;
  border-bottom: 1px solid #262626;
}
.price-list--no-margin {
  margin: 0;
}
.price-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 5px;
}
.price-list__item:last-child {
  margin-bottom: 0;
}
.price-list__label {
  color: #262626;
  font-size: 12px;
  font-weight: 700;
}
.price-list__label--border {
  -ms-flex-item-align: end;
}
.price-list__label--total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}
.price-list__label--total:before {
  content: "\e941";
  margin-right: 5px;
  line-height: 18px;
  color: #62CBC9;
  font-family: icomoon;
}
.price-list__price {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}
.price-list__price--pink {
  color: #62CBC9;
}
.price-list__price--border {
  -ms-flex-item-align: end;
}
.price-list__price--total {
  font-size: 16px;
}
@media (min-width: 768px) {
  .price-list {
    margin-bottom: 15px;
  }
  .price-list--no-margin {
    margin: 0;
  }
  .price-list__label--border {
    padding-right: 30px;
  }
  .price-list__price--border {
    padding-left: 30px;
  }
}
