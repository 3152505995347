.collectors {
  background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#bfbfbf),
      to(#d9d9d9)
    ),
    -webkit-gradient(linear, left bottom, left top, from(#303030), to(#1e1e1c));
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -webkit-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%),
    -o-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%),
    linear-gradient(to top, #303030 0, #1e1e1c 100%);
}
.collectors__wrapper {
  background: #f1f1f1;
}
.collectors__content {
  padding: 0 15px;
}
.collectors__title {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: center;
}
.collectors__title span {
  color: #262626;
  font-weight: 300;
}
.collectors__img img {
  display: block;
  width: 100%;
}
@media (min-width: 992px) {
  .collectors__title {
    margin-bottom: 50px;
    font-size: 55px;
  }
  .collectors__content {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .collectors {
    position: relative;
  }
}
