.info-page {
    background-image: -webkit-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
    background-image: -o-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
    background-image: linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
}
.info-page .section__title {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.info-page__wrapper {
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#bfbfbf),
    to(#d9d9d9)
  );
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%);
}
.info-page__wrapper.section {
  padding-bottom: 30px;
}
.info-page__content {
  max-width: 1150px;
  width: 100%;
  padding: 20px 15px;
  margin: 0 auto;
  background-color: #e5e5e5;
}
.info-page__content:after,
.info-page__content:before {
  content: " ";
  display: table;
}
.info-page__content:after {
  clear: both;
}
.info-page__section {
  margin-bottom: 20px;
}
.info-page__img-wrapper {
  max-width: 300px;
  margin: 0 auto 30px;
}
.info-page__img {
  display: block;
  max-width: 100%;
}
.info-page__text {
  margin-bottom: 10px;
  color: #262626;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: justify;
}
.info-page__text b {
  font-weight: 700;
}
.info-page__text a {
  color: #ff4677;
  text-decoration: underline;
}
.info-page__text a:hover {
  text-decoration: none;
}
.info-page__list {
  list-style: none;
  margin: 20px 0;
}
.info-page__list-item {
  margin-bottom: 15px;
  color: #262626;
  font-size: 18px;
  font-weight: 300;
}
.info-page__list-item:last-child {
  margin-bottom: 0;
}
.info-page__list-item:before {
  content: "\e941";
  color: #ff4677;
  font-family: icomoon;
}
@media (min-width: 768px) {
  .info-page__img-wrapper {
    margin-top: 5px;
    margin-right: 20px;
    margin-bottom: 10px;
    float: left;
  }
}
@media (min-width: 992px) {
  .info-page__wrapper.section {
    padding-bottom: 50px;
  }
  .info-page__content {
    padding: 30px 50px 40px;
  }
  .info-page__text {
    font-size: 18px;
  }
}
