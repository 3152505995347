.arrow-list {
  list-style: none;
}
.arrow-list--white .arrow-list__item {
  color: #fff;
}
.arrow-list__item {
  position: relative;
  padding-left: 20px;
  color: #262626;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}
.arrow-list__item:before {
  content: "\e941";
  position: absolute;
  top: 0;
  left: 0;
  color: #62CBC9;
  font-family: icomoon;
}
.arrow-list__item--no-arrow:before {
  content: none;
}
.arrow-list__item--no-margin {
  margin-bottom: 0;
}
.arrow-list__btn-wrapper {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .arrow-list__item {
    font-size: 18px;
    line-height: 24px;
  }
}
