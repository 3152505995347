.dealer {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#bfbfbf),
    to(#d9d9d9)
  );
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%);
}
.dealer__wrapper {
  background-image: -webkit-linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
  background-image: -o-linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
  background-image: linear-gradient(0deg, #bfbfbf 0, #d9d9d9);
}
.dealer__content {
  max-width: 1150px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  background-color: #ededed;
}
.dealer__info {
  margin-bottom: 15px;
  text-align: center;
}
.dealer__list {
  display: inline-block;
}
.dealer__list li {
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .dealer__info {
    margin-bottom: 30px;
  }
  .dealer__list {
    -webkit-columns: 2;
    columns: 2;
    -webkit-column-gap: 75px;
    column-gap: 75px;
  }
}
