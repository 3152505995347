.form-field,
.form-field__container:not(.form-field__container--hidden) {
  position: relative;
}

.form-field {
  margin-bottom: 10px;
}

.form-field--checkbox,
.form-field--radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.form-field--checkbox > .help-block,
.form-field--radio > .help-block {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.form-field--file {
  overflow: hidden;
}

.form-field--no-margin:not(.has-error) {
  margin-bottom: 0;
}

.form-field__container:not(.form-field__container--hidden) + .text-danger {
  margin-top: 5px;
}

.form-field__container--chars-left {
  font-size: 12px;
  margin-top: 2px;
}

.form-field__item {
  -moz-appearance: textfield;
  background-color: #f1f1f1;
  border: 0;
  border-left: 10px solid #262626;
  border-radius: 0;
  font-family: Area, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 55px;
  outline: 0;
  padding: 0 30px 0 10px;
  width: 100%;
}

.form-field__item::-webkit-inner-spin-button,
.form-field__item::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field__item::-webkit-contacts-auto-fill-button,
.form-field__item::-webkit-credentials-auto-fill-button {
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  visibility: hidden;
}

.form-field__item--is-filled + span,
.form-field__item:focus + span,
.form-field__item:focus ~ button,
.form-field__item:focus ~ label {
  color: #262626;
}

.form-field__item--is-filled,
.form-field__item:focus {
  background-color: #fff;
  border-bottom-color: transparent;
  -webkit-border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473fa),
      to(#62aed3)
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(bottom, #2473fa 0, #62aed3) 1
    100%;
  -o-border-image: -o-linear-gradient(bottom, #2473fa 0, #62aed3) 1 100%;
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473fa),
      to(#62aed3)
    )
    1 100%;
  border-image: linear-gradient(to top, #2473fa 0, #62aed3) 1 100%;
  border-right-color: transparent;
  border-style: solid;
  border-top-color: transparent;
  border-width: 10px 0 10px 10px;
  padding-bottom: 0;
  padding-top: 0;
}

.form-field__item:-moz-read-only {
  background-color: #f1f1f1;
  border: 0;
  border-left: 10px solid #262626;
}

.form-field__button,
.form-field__icon {
  background-color: transparent;
  border: 0;
}

.form-field__item:-moz-read-only + .form-field__icon {
  color: #262626;
}

.form-field__item:read-only + .form-field__icon {
  color: #262626;
}

.form-field__item--textarea {
  -ms-overflow-style: none;
  height: auto;
  min-height: 110px;
  padding-bottom: 10px;
  padding-top: 10px;
  resize: none;
}

.form-field__item--textarea::-webkit-scrollbar {
  display: none;
}

.form-field__item--file {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  z-index: 0;
}

.form-field__item:focus {
  padding-bottom: 0;
  padding-top: 0;
}

.form-field__item:-moz-read-only {
  padding-bottom: 10px;
  padding-top: 10px;
}

.form-field__item:read-only {
  background-color: #f1f1f1;
  border: 0;
  border-left: 10px solid #262626;
  padding-bottom: 10px;
  padding-top: 10px;
}

.form-field__icon {
  color: #bebebe;
  font-size: 22px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.form-field__button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #cbcbcb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  line-height: 20px;
  padding: 0 4px 0 14px;
}

.form-field__button i {
  font-size: 16px;
  line-height: inherit;
}

.form-checkbox,
.form-radio {
  border: 3px solid #fff;
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 32px;
  flex: 0 1 32px;
  font-size: 25px;
  height: 32px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 32px;
}

.form-checkbox.disabled,
.form-radio.disabled {
  opacity: 0.5;
}

.form-checkbox__label,
.form-radio__label {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  min-width: 50%;
  word-wrap: break-word;
}

.form-checkbox--black,
.form-radio--black {
  border: 3px solid #262626;
  color: #262626;
}

.form-checkbox--white,
.form-radio--white {
  border: 3px solid #fff;
  color: #fff;
}

.form-checkbox--pink,
.form-radio--pink {
  border: 3px solid #ff4677;
  color: #ff4677;
}

.form-checkbox input,
.form-radio input {
  display: none;
}

.form-checkbox i.checked,
.form-checkbox input:checked + i,
.form-radio i.checked,
.form-radio input:checked + i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.form-checkbox i,
.form-radio i {
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  line-height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
}

.form-checkbox {
  border-radius: 3px;
}

.form-checkbox i {
  font-size: 23px;
}

.form-radio {
  border-radius: 50%;
}

.form-radio i {
  font-size: 16px;
}

.text-danger {
  color: #ff4677;
}
