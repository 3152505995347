.consoles-slider .slick-next:before,
.consoles-slider .slick-prev:before,
.consoles-slider__selected:before {
  font-family: icomoon;
  font-size: 30px;
}
.consoles-slider {
  padding: 0 30px;
}
.consoles-slider .slick-slider .slick-list {
  overflow: visible;
}
.consoles-slider .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.consoles-slider .slick-next,
.consoles-slider .slick-prev {
  width: 30px;
  height: 30px;
}
.consoles-slider .slick-prev {
  left: -15px;
  z-index: 100;
}
.consoles-slider .slick-prev:before {
  content: "\e942";
}
.consoles-slider .slick-next {
  right: -15px;
  z-index: 100;
}
.consoles-slider .slick-next:before {
  content: "\e941";
}
.consoles-slider .slick-disabled {
  opacity: 0;
  visibility: hidden;
}
.consoles-slider .square {
  margin: 0 auto;
}
.consoles-slider__item {
  position: relative;
  padding: 40px 0 110px;
  text-align: center;
}
.consoles-slider__item-btn {
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #262626;
}
.consoles-slider__item-btn:focus,
.consoles-slider__item-btn:hover {
  color: #f6687f;
  outline: 0;
}
.consoles-slider__item-btn.active {
  color: #ff4677;
}
.consoles-slider__item-btn:disabled {
  color: #343434;
  opacity: 0.5;
}
.consoles-slider__selected {
  position: relative;
}
.consoles-slider__selected:before {
  content: "\e943";
  position: absolute;
  top: -40px;
  left: 50%;
  color: #62cbc9;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.consoles-slider__icon {
  font-size: 55px;
}
.consoles-slider__content {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 220px;
  padding-top: 25px;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.consoles-slider__content .btn {
  max-width: 160px;
  width: 100%;
}
.consoles-slider__name {
  margin-bottom: 25px;
  color: #262626;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
}
.consoles-slider__name b {
  font-weight: 700;
}
@media (min-width: 1460px) {
  .consoles-slider {
    padding: 0;
  }
  .consoles-slider .slick-prev {
    left: -30px;
  }
  .consoles-slider .slick-next {
    right: -30px;
  }
}
