.form-field__item--select {
  position: static;
  padding: 10px 0;
}
.form-field__item--select.is-focused {
  padding: 0;
  background-color: #fff;
  border-width: 10px 0 10px 10px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(bottom, #2473FA 0, #62AED3) 1
    100%;
  -o-border-image: -o-linear-gradient(bottom, #2473FA 0, #62AED3) 1 100%;
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  border-image: linear-gradient(to top, #2473FA 0, #62AED3) 1 100%;
}
.Select-option.is-focused,
.Select-option.is-selected {
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
}
.form-field__item--select.is-focused:not(.is-open) > .Select-control {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.form-field__item--select.is-open .Select-control .Select-arrow {
  border-width: 0 8px 8px;
}
.Select.is-disabled > .Select-control,
.form-field__item--select.is-disabled {
  background-color: #e9ecef;
}
.form-field__item--select.has-value .Select-control {
  overflow: visible;
}
.form-field__item--select.has-value.is-clearable .Select-value {
  padding-left: 75px;
}
.form-field__item--select.has-value.is-clearable .Select-input {
  padding-left: 65px;
}
.form-field__item--select.has-value .Select-clear-zone {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 55px;
  background-color: #262626;
}
.form-field__item--select.has-value
  .Select-clear-zone:hover
  .Select-clear:before {
  color: #ff4677;
}
.form-field__item--select.has-value .Select-clear {
  font-size: 0;
}
.form-field__item--select.has-value .Select-clear:before {
  content: "\e978";
  color: #fff;
  font-size: 36px;
  font-family: icomoon;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.form-field__item--select .Select-control {
  position: static;
  border: none;
  height: 35px;
  background-color: #f1f1f1;
}
.search-select,
.search-select .Select,
.search-select .Select.is-focused .Select-control {
  background-color: transparent;
}
.form-field__item--select .Select-control:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-field__item--select .Select-control .Select-value,
.form-field__item--select .Select-placeholder {
  padding: 0 20px;
  line-height: 55px;
}
.form-field__item--select .Select-arrow-zone {
  padding-right: 0;
  right: 10px;
}
.form-field__item--select .Select-arrow {
  border-width: 8px 8px 2.5px;
}
.search-select {
  height: 38px;
  margin-bottom: 0;
  font-size: 14px;
  border-radius: 10px;
  border: 3px solid #fff;
}
.search-select .form-field__container {
  position: static;
}
.search-select .Select {
  height: 32px;
  padding: 0;
  border: none;
}
.search-select .Select.has-value .Select-control,
.search-select .Select.has-value .Select-value {
  padding: 0 10px;
}
.search-select .Select-control {
  position: static;
  height: 32px;
  color: #fff;
  background-color: transparent;
}
.search-select .Select-control .Select-input {
  color: #fff;
  height: 32px;
  padding-right: 40px;
  padding-left: 10px;
}
.search-select .Select-control .Select-value {
  padding: 0 10px;
  line-height: 32px;
}
.search-select .Select-control .Select-value-label,
.search-select
  .Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: #fff;
}
.search-select .Select-control input {
  color: #fff;
  height: 32px;
  padding: 0;
}
.search-select .Select-placeholder {
  padding: 0 10px;
  line-height: 32px;
  border-radius: 10px;
}
.search-select .Select-arrow-zone,
.search-select .Select-clear-zone,
.search-select .Select-loading-zone {
  display: none;
}
.Select-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.Select-arrow-zone {
  -ms-flex-preferred-size: 20px;
  flex-basis: 20px;
}
.Select-multi-value-wrapper {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.Select-input {
  width: 100%;
  overflow: hidden;
}
.Select-input input {
  width: 100% !important;
}
.Select-menu-outer {
  max-height: 220px;
  border: none;
  border-radius: 0;
  z-index: 999;
}
.Select-menu {
  max-height: 220px;
  border-bottom: 10px solid #262626;
}
.Select.drop-up .Select-menu-outer {
  margin-top: 0;
  top: auto;
  bottom: 100%;
}
.Select.is-disabled .Select-arrow-zone {
  opacity: 0.35;
}
.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333;
}
.Select--single > .Select-control .Select-value,
.Select-placeholder {
  color: #868e96;
}
.Select--multi.is-disabled .Select-value-icon {
  border-right: 1px solid #e3e3e3;
}
.Select--multi.is-disabled .Select-value-icon:active,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:hover {
  background-color: #fcfcfc;
}
.Select-option {
  color: #262626;
  font-size: 16px;
  font-weight: 300;
  padding: 15px;
  border-left: 10px solid #343434;
  background-color: #e5e5e5;
}
.Select-option.is-focused,
.Select-option.is-selected,
.Select-option:hover {
  padding-top: 5px;
  padding-bottom: 5px;
  border-width: 10px 0 10px 10px;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(bottom, #2473FA 0, #62AED3) 1
    100%;
  -o-border-image: -o-linear-gradient(bottom, #2473FA 0, #62AED3) 1 100%;
}
.Select-option.is-selected {
  font-weight: 700;
  border-image: linear-gradient(to top, #2473FA 0, #62AED3) 1 100%;
}
.Select-option.is-focused {
  background-color: #e5e5e5;
  border-image: linear-gradient(to top, #2473FA 0, #62AED3) 1 100%;
}
.Select-option:nth-child(2n),
.Select-option:nth-child(2n).is-focused {
  background-color: #fff;
}
.Select-option:last-child {
  border-radius: 0;
}
.Select-option:hover {
  border-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#2473FA),
      to(#62AED3)
    )
    1 100%;
  border-image: linear-gradient(to top, #2473FA 0, #62AED3) 1 100%;
}
