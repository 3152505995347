.footer__social-text b,
.footer__title {
  font-weight: 700;
}
.footer {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.footer .container-fluid {
  padding: 0;
}
.footer__top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#3b3b3a),
    to(#2d2e2c)
  );
  background-image: -webkit-linear-gradient(bottom, #3b3b3a 0, #2d2e2c 100%);
  background-image: -o-linear-gradient(bottom, #3b3b3a 0, #2d2e2c 100%);
  background-image: linear-gradient(to top, #3b3b3a 0, #2d2e2c 100%);
}
.footer__top-content {
  padding-top: 20px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#2d2d2d),
    to(#2d2d2d)
  );
  background-image: -webkit-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: -o-linear-gradient(bottom, #303030 0, #1e1e1c 100%);
  background-image: linear-gradient(to top, #303030 0, #1e1e1c 100%);
}
.footer__info {
  padding: 0 15px;
}
.footer__title {
  margin-bottom: 20px;
  color: #f1f1f1;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}
.footer__text {
  margin-bottom: 15px;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: justify;
}
.footer__logo-wrapper {
  margin-bottom: 20px;
  text-align: center;
}
.footer__logo {
  display: inline-block;
}
.footer__be-social {
  display: none;
}
.footer__menu-item {
  padding: 10px 0;
  text-align: center;
}
.footer__list {
  list-style: none;
}
.footer__list-item {
  margin: 0 15px 10px;
  font-size: 18px;
  font-weight: 400;
}
.footer__list-link {
  color: #9f9f9f;
}
.footer__contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 20px 0 10px;
  background-color: rgba(0, 0, 0, 0.15);
}
.footer__contacts-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px 10px;
  color: #F6687F;
  font-size: 18px;
  font-weight: 400;
}
.footer__contacts-item b {
  margin-left: 5px;
  font-weight: 700;
}
.footer__contacts-icon {
  margin-right: 10px;
  color: #fff;
  font-size: 40px;
}
.footer__bottom {
  background-color: #424242;
}
.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 0;
  background-image: -webkit-linear-gradient(356deg, #61aed4 0, #2573fa 100%);
  background-image: -o-linear-gradient(356deg, #61aed4 0, #2573fa 100%);
  background-image: linear-gradient(-266deg, #61aed4 0, #2573fa 100%);
}
.footer__social-text {
  margin-right: 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}
.footer__copyright {
  padding: 20px 0 10px;
  background-color: #FAFAFA;
}
.footer__address {
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
}
@media (min-width: 768px) {
  .footer__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer__logo-wrapper {
    margin-right: 30px;
    margin-bottom: 0;
  }
  .footer__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer__menu-item {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 20px 0;
  }
  .footer__text:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer__copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background-color: #FAFAFA;
  }
  .footer__logo-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .footer__top-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .footer__info {
    max-width: 45%;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    padding-right: 20px;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .footer__logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .footer__nav {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .footer__title {
    text-align: left;
  }
  .footer__text {
    line-height: 18px;
  }
  .footer .links__link {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .footer .links__icon {
    display: block;
    width: 30px;
  }
  .footer__menu-item {
    padding: 0 0 20px 20px;
    margin: 0;
    text-align: left;
    border-left-width: 3px;
    border-left-style: solid;
    border-color: rgba(0, 0, 0, 0.15);
  }
  .footer__list-item {
    font-size: 16px;
    margin: 0 10px 10px;
  }
  .footer__list-link:hover {
    color: #f1f1f1;
  }
  .footer__list-link.active {
    color: #f6687f;
    font-weight: 700;
  }
  .footer__contacts {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer__bottom-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .footer__social {
    position: relative;
    max-width: 45%;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .footer__copyright {
    background-color: #FAFAFA;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .footer__address {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .footer__top {
    padding-top: 0;
  }
  .footer__top-content {
    padding-top: 35px;
  }
  .footer__title {
    margin-bottom: 15px;
  }
  .footer__info {
    max-width: 565px;
    padding: 10px 55px 45px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .footer__logo-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 55px;
  }
  .footer__be-social {
    position: relative;
    display: block;
    color: #fff;
    font-size: 22px;
    font-weight: 300;
  }
  .footer__be-social b {
    font-weight: 700;
  }
  .footer__be-social:after {
    content: "\e943";
    position: absolute;
    left: 50%;
    bottom: -30px;
    color: #62cbc9;
    font-size: 30px;
    font-family: icomoon;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .footer__text-wrapper {
    max-width: 210px;
  }
  .footer__text {
    letter-spacing: -0.5px;
  }
  .footer__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer__menu {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .footer__menu-item {
    padding-bottom: 0;
  }
  .footer__contacts {
    padding: 15px 0;
  }
  .footer__contacts-item {
    margin: 0 0 0 80px;
  }
  .footer__contacts-item:first-child {
    margin-left: 0;
  }
  .footer__social {
    max-width: 565px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .footer__social .social {
    margin-left: 18%;
  }
  .footer__social-text {
    display: none;
  }
}
@media (min-width: 1400px) {
  .footer__contacts-item {
    margin-left: 120px;
  }
}
