.square {
  position: relative;
  display: block;
  width: 107px;
  height: 107px;
  color: #262626;
  background-color: transparent;
  text-align: center;
}
.square:after,
.square:before {
  content: "";
  display: block;
  width: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.square:before {
  left: 0;
  border: 8px solid #262626;
  border-right: 0;
  border-radius: 10px 0 0;
}
.square:after {
  right: 0;
  border: 8px solid #262626;
  border-left: 0;
  border-radius: 0 10px 10px 0;
}
.square--gradient:before {
  border-color: #62AED3;
}
.square--gradient:after {
  border-color: #2473FA;
}
.square--gradient .square__content:after,
.square--gradient .square__content:before {
  background: -webkit-linear-gradient(-266deg,#62AED3,#2473FA);
  background: -o-linear-gradient(-266deg,#62AED3,#2473FA);
  background: linear-gradient(-266deg,#62AED3,#2473FA);
}
.square--small {
  width: 80px;
  height: 80px;
}
.square--small:after,
.square--small:before {
  border-width: 6px;
}
.square--small .square__content:after,
.square--small .square__content:before {
  height: 6px;
}
.square--big {
  width: 167px;
  height: 167px;
}
.square--big:after,
.square--big:before {
  border-width: 12px;
}
.square--big .square__content:after,
.square--big .square__content:before {
  height: 12px;
}
.square__content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 2px 5px;
  margin: 0 10px;
  line-height: 28px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.square__content:after,
.square__content:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 8px;
  right: 0;
  left: 0;
  background: #262626;
}
.square__content:before {
  top: 0;
  bottom: auto;
}
.square__content:after {
  top: auto;
  bottom: 0;
}
.square__helper-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
