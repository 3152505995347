.faq__list li,.faq__text{
    font-size:18px;
    font-weight:300;
    line-height:24px
}
.faq .arrow-list__item:before{
    -webkit-transition:.3s;
    -o-transition:.3s;
    transition:.3s
}
.faq__toggle{
    display:none
}
.faq__toggle:checked~.arrow-list__item:before{
    -webkit-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    transform:rotate(90deg)
}
.faq__toggle:checked~.faq__content{
    height:auto;
    overflow-y:auto
}
.faq__content{
    height:0;
    overflow:hidden
}
.faq__text{
    letter-spacing:-.89px;
    margin-bottom:20px
}
.faq__text p{
    margin-bottom:10px
}
.faq__text a{
    color:#ff4677;
    text-decoration:underline
}
.faq__text a:hover{
    text-decoration:none
}
.faq__list{
    padding-left:15px
}
.faq__table{
    border-spacing: 16px;
    border-collapse: separate;
}

