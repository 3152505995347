.cert {
    background-color: #c5c7c9;
  }
  .cert__content {
    width: 100%;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#bfbfbf),
      to(#d9d9d9)
    );
    background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
    background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
    background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%);
  }
  .cert__section {
    background-color: #e5e5e5;
  }
  @media (min-width: 1200px) {
    .cert__section {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .cert__search_form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 20px;
    justify-content: center;
  }
  
  .cert__search_img {
    display: block;
    max-width: 80%;
    margin: 0 auto 40px auto;
  }

  .cert__search_tip {
    text-align: center;
  }

  .cert__section_small {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .cert__search_area {
    text-align: center;
    margin-bottom: 50px;
  }

  .cert__search_why {
    margin: 0 auto 50px auto;
    max-width: 70%;
  }

  .cert__details_table {
    width: 100%;
    margin-bottom: 50px;
    table-layout: fixed;
    border-collapse: collapse;
    border-bottom: 1px solid #000;
  }

  .cert__details_table th { 
    padding: 10px;
    text-align: right;
    border-bottom: 1px solid #000;
  }

  .cert__details_table td { 
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #000;
  }

  .cert__details_img {
    display: block;
    max-width: 80%;
    margin: 0 auto 40px auto;
  }


