.scale__title,
.slider-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
}
.rangeslider-vertical--fluid {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 100%;
}
.rangeslider-vertical--fluid .rc-slider-handle:after,
.rangeslider-vertical--fluid .rc-slider-handle:before {
  content: "";
  display: none !important;
  position: absolute;
  top: 0;
  -webkit-transform: translateY(calc(-50% + 4px));
  -ms-transform: translateY(calc(-50% + 4px));
  transform: translateY(calc(-50% + 4px));
}
.rangeslider-vertical--fluid .rc-slider-handle:before {
  width: 90px;
  height: 48px;
  background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: linear-gradient(-266deg, #62aed3, #2473fa);
  left: -160px;
}
.rangeslider-vertical--fluid .rc-slider-handle:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #2473fa;
  left: -71px;
}
.rangeslider-vertical--fluid .rc-slider-rail {
  background-color: #262626;
}
.rangeslider-vertical--fluid .rc-slider-mark {
  left: -30px;
}
.rangeslider-vertical--fluid .rc-slider-track {
  background-color: transparent;
}
.rangeslider-vertical--fluid .rc-slider-mark-text {
  color: #262626;
  font-size: 18px;
  font-weight: 300;
}
.rangeslider-vertical--fluid .rc-slider-dot {
  left: 0;
  width: 18px;
  height: 18px;
  margin-left: -7px;
  margin-bottom: -9px;
  background-color: #f1f1f1;
  border: 5px solid #262626;
}
.rangeslider-vertical--fluid .rc-slider-dot:first-child {
  width: 24px;
  height: 7px;
  margin-left: -10px;
  border: none;
  border-radius: 0;
  background-color: #262626;
}
.rangeslider-vertical--fluid .rc-slider-dot:last-child {
  margin-bottom: -9px;
}
.rangeslider-vertical--fluid .rc-slider-handle {
  left: 0;
  width: 20px;
  height: 20px;
  margin-left: -3px;
  border: 5px solid #ff4677;
  background-color: #f1f1f1;
}
.helper-line__arrow--bottom:before,
.helper-line__arrow--top:before {
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.slider-vertical {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 35px;
  padding-top: 20px;
  padding-left: 25px;
}
.scale {
  background-color: #cbcbcb;
}
.scale__wrapper {
  background-color: #f1f1f1;
}
.scale__title {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  color: #262626;
  font-size: 55px;
  font-weight: 300;
  letter-spacing: -0.5px;
}
.scale__title img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: block;
  max-width: 190px;
  margin: 0 10px;
}
.scale__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
}
.scale__info {
  width: 100%;
}
.scale__tooltip {
  margin-left: 5px;
  padding: 0 2px;
}
.text {
  margin-bottom: 20px;
}
.pos {
  position: relative;
}
.helper-line {
  position: absolute;
  background: #262626;
  z-index: 20;
  width: 2px;
  top: 0;
  left: -25px;
}
.helper-line__arrow {
  position: absolute;
  width: 2px;
  height: 20px;
  background: #262626;
  display: block;
}
.helper-line__arrow--top {
  right: 0;
  left: auto;
  top: auto;
  bottom: -10px;
}
.helper-line__arrow--top:before {
  position: absolute;
  right: 1px;
  display: block;
  border-right: 10px solid #262626;
}
.helper-line__arrow--bottom {
  right: auto;
  left: 0;
  bottom: auto;
  top: -10px;
}
.helper-line__arrow--bottom:before {
  position: absolute;
  left: 1px;
  display: block;
  border-left: 10px solid #262626;
}
.scale__btn-modal {
  display: block;
  padding: 0;
  border: 0;
}
@media (min-width: 992px) {
  .scale__title {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 135px;
    margin-bottom: 60px;
  }
  .scale__content {
    max-width: 1280px;
    width: 100%;
    padding-left: 135px;
  }
  .slider-vertical {
    padding-top: 25px;
    padding-bottom: 100px;
    margin-right: 60px;
  }
  .helper-line {
    width: 8px;
    left: -35px;
  }
  .helper-line__arrow {
    width: 8px;
  }
  .helper-line__arrow--top:before {
    right: 7px;
  }
  .helper-line__arrow--bottom:before {
    left: 7px;
  }
  .rangeslider-vertical--fluid .rc-slider-handle:after,
  .rangeslider-vertical--fluid .rc-slider-handle:before {
    display: block !important;
  }
}
