.article {
  background-color: #dadada;
}
.article__content {
  padding: 0 15px;
  background-color: #f1f1f1;
}
.article__header {
  position: relative;
}
.article__header-nav {
  position: absolute;
  top: 25px;
  left: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.article__header-item {
  background-color: #262626;
}
.article__header-item--ytr {
  background-image: -webkit-linear-gradient(356deg, #f8ba64 0, #fb768b 100%);
  background-image: -o-linear-gradient(356deg, #f8ba64 0, #fb768b 100%);
  background-image: linear-gradient(-266deg, #f8ba64 0, #fb768b 100%);
}
.article__header-item--btp {
  background-color: #62CBC9;
}
.article__header-next,
.article__header-prev {
  display: block;
  color: #fff;
  font-size: 26px;
  line-height: 26px;
}
.article__title {
  padding: 0 35px;
  color: #fff;
  font-size: 60px;
  text-align: center;
}
.article__subtitle {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  text-align: center;
  letter-spacing: -4px;
}
.article__author {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto 30px;
  text-align: center;
}
.article__author .info-block__info {
  position: relative;
  max-width: 500px;
}
.article__author .info-block__img-wrapper {
  margin-bottom: 15px;
}
.article__author .info-block__img {
  max-width: 100%;
}
.article__author .info-block__contact {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.article__author-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.article__author-date,
.article__author-name {
  display: inline-block;
  margin-bottom: 15px;
  color: #262626;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: -1.2px;
}
.article__author-date b,
.article__author-name b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .article__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .article__header-item,
  .article__title {
    display: -webkit-box;
    display: -ms-flexbox;
  }
  .article__header-nav {
    top: 60%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .article__header-item {
    display: flex;
    padding-top: 30px;
    max-width: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .article__content {
    padding: 0;
  }
  .article__title {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
    padding: 0;
    font-weight: 700;
    line-height: 50px;
    text-align: right;
    letter-spacing: -5px;
  }
  .article__subtitle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 30px;
    text-align: left;
  }
  .article__subtitle:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    display: block;
    width: 50px;
    height: 5px;
    background-color: #262626;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .article .info-block__header {
    padding: 40px 15px;
  }
  .article .editable__content {
    padding: 0 15px;
  }
  .article__title {
    font-size: 80px;
    line-height: 70px;
  }
  .article__subtitle {
    font-size: 40px;
  }
  .article__subtitle:before {
    width: 70px;
  }
  .article__header-next,
  .article__header-prev {
    font-size: 45px;
  }
  .article__author .info-block__info {
    max-width: 515px;
  }
  .article__author .info-block__contact {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .article .info-block__header:before {
    right: 0;
  }
  .article__header-nav {
    top: 70%;
  }
  .article__header-item {
    padding-top: 70px;
  }
  .article__title {
    font-size: 140px;
    line-height: 120px;
    letter-spacing: -10px;
  }
  .article__subtitle {
    font-size: 60px;
  }
  .article__subtitle--small {
    font-size: 40px;
  }
  .article__subtitle:before {
    width: 100px;
    height: 12px;
  }
}
