.game-details__notes-label span,
.game-details__title span {
  font-weight: 700;
}
.game-details__img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 140px;
  color: #bebebe;
}
.game-details__img {
  display: block;
  max-width: 200px;
  max-height: 200px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
}
.game-details__settings-item {
  margin-bottom: 20px;
}
.game-details__title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  border-bottom: 4px solid #262626;
}
.game-details__title:before {
  content: "\e941";
  margin-right: 5px;
  color: #62CBC9;
  font-size: 20px;
  font-family: icomoon;
}
.game-details__list {
  margin-bottom: 20px;
  list-style: none;
}
.game-details__list-item {
  margin-bottom: 20px;
}
.game-details__list-item span {
  margin-bottom: 5px;
  display: block;
  color: #262626;
  font-size: 12px;
  font-weight: 700;
}
.game-details__notes-label {
  color: #262626;
  font-size: 12px;
  line-height: 36px;
}
@media (min-width: 768px) {
  .game-details__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 5px;
    border: 10px solid #262626;
  }
  .game-details__game {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .game-details__img-wrapper {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-bottom: 35px;
  }
  .game-details__info {
    padding: 0 20px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .game-details__list {
    padding-left: 15px;
  }
  .game-details__list-item {
    margin-bottom: 15px;
  }
  .game-details__settings-item {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .game-details__content {
    padding: 20px 0;
  }
  .game-details__title {
    margin-bottom: 10px;
  }
  .game-details__list-item {
    margin-bottom: 5px;
  }
  .game-details__list-item span {
    display: inline-block;
    margin-bottom: 0;
  }
}
