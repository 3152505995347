.ons__text {
  color: #fff;
  font-weight: 300;
  line-height: 26px;
}
.ons__price b,
.ons__text b {
  font-weight: 700;
}
.ons {
  background-color: #000000;
}
.ons__content {
  padding: 0 15px;
}
.ons__items {
  margin-bottom: 45px;
}
.ons__item {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  margin: 0 auto 15px;
  border-radius: 10px;
  border: 2px dotted #fff;
}
.ons__item:last-child {
  margin-bottom: 0;
}
.ons__info-wrapper {
  margin-bottom: 10px;
  text-align: center;
}
.ons__icon {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 82px;
}
.ons__title {
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
  letter-spacing: -1px;
}
.ons__price {
  color: #fff;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -2px;
  display: inline-block;
}
.ons__price-extras {
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
  letter-spacing: -1px;
}
.ons__text {
  font-size: 16px;
  text-align: justify;
  letter-spacing: -1px;
}
.ons__text a {
  color: #73c2f2;
  font-weight: 500;
}
.ons__tooltip {
  padding: 0 2px;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .ons__item {
    max-width: 100%;
  }
  .ons__info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }
  .ons__icon {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .ons__title {
    margin-bottom: 10px;
  }
  .ons__price {
    position: relative;
    line-height: 36px;
    padding-left: 20px;
  }
  .ons__price:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 36px;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (min-width: 992px) {
  .ons .section__line-through {
    margin-bottom: 50px;
    font-size: 36px;
  }
  .ons__content {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
  .ons__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 30px;
  }
  .ons__info-wrapper {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 400px;
    width: 100%;
    margin-bottom: 0;
  }
  .ons__info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .ons__title {
    position: relative;
    padding-right: 40px;
  }
  .ons__title:after {
    content: "\e941";
    position: absolute;
    top: 50%;
    right: 15px;
    color: #62CBC9;
    font-size: 18px;
    font-family: icomoon;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .ons__text-wrapper {
    padding-left: 20px;
    border-left: 2px solid #fff;
  }
  .ons__text {
    font-size: 18px;
  }
}
