.links {
  list-style: none;
}
.links__item {
  margin-bottom: 20px;
}
.links__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #FF708B;
  font-size: 18px;
  font-weight: 600;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}
.links__link span {
  color: #fff;
  font-size: 30px;
  margin-right: 10px;
}
.links__link.active,
.links__link:active,
.links__link:focus,
.links__link:hover {
  outline: 0;
}
