.learn {
    background-image: -webkit-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
    background-image: -o-linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
    background-image: linear-gradient(0deg,#bfbfbf 0,#d9d9d9);
}
.learn__content {
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#bfbfbf),
    to(#d9d9d9)
  );
  background-image: -webkit-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: -o-linear-gradient(bottom, #bfbfbf 0, #d9d9d9 100%);
  background-image: linear-gradient(to top, #bfbfbf 0, #d9d9d9 100%);
}
.learn__section {
  background-color: #e5e5e5;
}
@media (min-width: 1200px) {
  .learn__section {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
}
