html {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  height: 100%;
}

body {
  text-align: left;
  font-weight: 400;
  overflow-x: visible !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Area, sans-serif;
  word-wrap: break-word;
}

button,
input[type="button"],
input[type="submit"],
label {
  cursor: pointer;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

