.section {
  padding-top: 30px;
  padding-bottom: 30px;
}
.section--blog-top {
  padding-top: 233px;
  padding-bottom: 0;
}
.section__title {
  margin-bottom: 40px;
  color: #262626;
  font-family: Area, sans-serif;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}
.section__title--white {
  color: #fff;
}
.section__line-through,
.section__subtitle {
  margin-bottom: 40px;
  color: #262626;
  text-align: center;
}
.section__title span {
  font-weight: 400;
}
.section__line-through b,
.section__subtitle b,
.section__subtitle span {
  font-weight: 700;
}
.section__title--icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.section__icon {
  font-size: 45px;
  margin-right: 10px;
}
.section__subtitle {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: -1px;
}
.section__line-through {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 400;
}
.section__line-through span {
  margin: 0 10px;
}
.section__line-through:after,
.section__line-through:before {
  content: "";
  display: block;
  height: 3px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: #262626;
}
.section__line-through--white {
  color: #fff;
}
.section__line-through--white:after,
.section__line-through--white:before {
  background-color: #fff;
}
.section--top {
  padding-top: 60px;
}
.section--gradient {
  position: relative;
  padding-bottom: 60px;
}
.section--gradient:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 20;
  display: block;
  max-width: 1430px;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.section--ytr:after {
  background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: linear-gradient(-266deg, #62aed3, #2473fa);
}
.section--btp:after {
  background-image: -webkit-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: -o-linear-gradient(-266deg, #62aed3, #2473fa);
  background-image: linear-gradient(-266deg, #62aed3, #2473fa);
}
@media (min-width: 768px) {
  .section__title {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section__subtitle {
    max-width: 930px;
    width: 100%;
    margin: 0 auto 40px;
    font-size: 18px;
    line-height: 24px;
  }
  .section--gradient {
    padding-bottom: 100px;
  }
  .section--gradient:after {
    height: 50px;
  }
  .section--top {
    padding-top: 335px;
  }
  .section--blog-top {
    padding-top: 233px;
    padding-bottom: 0;
  }
}
