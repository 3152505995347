.pricing .arrow-list__item b,.pricing__title{
    font-weight:700
}
.pricing{
    background-image:-webkit-gradient(linear,left bottom,left top,from(#3b3b3a),to(#2d2e2c));
    background-image:-webkit-linear-gradient(bottom,#3b3b3a 0,#2d2e2c 100%);
    background-image:-o-linear-gradient(bottom,#3b3b3a 0,#2d2e2c 100%);
    background-image:linear-gradient(to top,#3b3b3a 0,#2d2e2c 100%)
}
.pricing .arrow-list{
    padding:0 20px
}
.pricing .arrow-list__item{
    color:#fff;
    font-weight:300;
    letter-spacing:-1px
}
.pricing__wrapper{
    background-image:-webkit-gradient(linear,left bottom,left top,from(#303030),to(#1e1e1c));
    background-image:-webkit-linear-gradient(bottom,#303030 0,#1e1e1c 100%);
    background-image:-o-linear-gradient(bottom,#303030 0,#1e1e1c 100%);
    background-image:linear-gradient(to top,#303030 0,#1e1e1c 100%)
}
.pricing__content{
    padding:0 15px
}
.pricing__item--wrapper{
    -webkit-box-flex:1;
    -ms-flex:1 1 280px;
    flex:1 1 280px;
    position:relative;
    max-width:280px;
    width:100%;
    margin:0 20px 30px;
    border:2px dotted #fff;
    border-radius:10px
}
.pricing__item--wrapper .arrow-list{
    margin-bottom:30px
}
.pricing__item--wrapper.active{
    border:none
}
.pricing__item--wrapper.active:after,.pricing__item--wrapper.active:before{
    content:'';
    position:absolute;
    left:0;
    right:0;
    display:block;
    height:20px
}
.pricing__item--wrapper.active:before{
    top:0;
    border:2px solid #62aed3;
    border-bottom:none;
    border-radius:10px 10px 0 0
}
.pricing__item--wrapper.active:after{
    bottom:0;
    border:2px solid #2473fa;
    border-top:none;
    border-radius:0 0 10px 10px
}
.pricing__item--wrapper.active .pricing__item:after,.pricing__item--wrapper.active .pricing__item:before{
    content:'';
    position:absolute;
    top:10px;
    bottom:10px;
    display:block;
    width:2px;
    background-image: -webkit-linear-gradient(-158deg, #62aed3, #2473fa);
    background-image: -o-linear-gradient(-158deg, #62aed3, #2473fa);
    background-image: linear-gradient(-158deg, #62aed3, #2473fa);
}
.pricing__item--wrapper.active .pricing__item:before{
    left:0
}
.pricing__item--wrapper.active .pricing__item:after{
    right:0
}
.pricing__item{
    padding:30px 20px
}
.pricing__item-title{
    margin-bottom:35px;
    color:#fff;
    font-size:30px;
    font-weight:700;
    padding:5px 0;
    border-top:5px solid #fff;
    border-bottom:5px solid #fff;
    letter-spacing:-.5px;
    text-align:center
}
.pricing__item-platform{
    margin-bottom:12px;
    color:#fff;
    font-size:20px;
    font-weight:700;
    padding:5px 0;
    text-align:center;
    text-transform: uppercase
}
.pricing__item-platform-divider{
    margin-bottom:12px;
    margin-top:20px;
    padding:5px 0;
    border-top:1px solid #555
}
.pricing__title{
    margin-bottom:30px;
    font-size:26px;
    text-align:center
}
.pricing__title span{
    font-weight:300
}
.pricing__subtitle{
    margin-bottom:20px;
    color:#fff;
    font-size:16px;
    font-weight:300;
    text-align:center;
    line-height:24px;
    letter-spacing:-.8px
}
.pricing__label,.pricing__price b,.pricing__subtitle b{
    font-weight:700
}
.pricing__icon{
    display:inline-block;
    width:100%;
    margin-bottom:20px;
    font-size:95px;
    text-align:center
}
.pricing__list{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    list-style:none
}
.pricing__list-item{
    position:relative;
    max-width:50%;
    -webkit-box-flex:1;
    -ms-flex-positive:1;
    flex-grow:1;
    text-align:center
}
.pricing__list-item .pricing__price:before{
    content:'';
    position:absolute;
    left:-1px;
    bottom:0;
    width:2px;
    height:35px;
    background-color:#fff
}
.pricing__list-item:first-child .pricing__price:before{
    content:none
}
.pricing__label{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    margin-bottom:20px;
    color:#fff;
    font-size:12px;
    letter-spacing:-.5px
}
.pricing__price{
    color:#fff;
    font-size:32px;
    font-weight:300;
    letter-spacing:-2px
}

.pricing__tooltip{
    padding:0 2px;
    margin-left:5px;
    font-size:16px
}
@media (min-width:768px){
    .pricing__items{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-box-pack:justify;
        -ms-flex-pack:justify;
        justify-content:center
    }
    .pricing__footer{
        max-width:870px;
        width:100%;
        margin:0 auto
    }
}
@media (min-width:992px){
    .pricing__content{
        max-width:1200px;
        width:100%;
        margin:0 auto
    }
    .pricing__title{
        margin-bottom:45px;
        font-size:55px
    }
    .pricing__subtitle{
        margin-bottom:50px;
        font-size:18px
    }
    .pricing__items{
        margin-bottom:35px
    }
}
